<hrt-table
  [tableId]="tableId"
  [columnDefinitions]="columnDefinitions"
  [defaultTableSettings]="defaultTableConfig"
  [presetFilters]="presetFilter"
  [tableData]="tableData"
  [totalCount]="totalCount"
  [hasExport]="true"
  (exportClicked)="exportAssetService()"
  (searchText)="updateSearchTerm($event)"
  (rowClicked)="rowClicked($event)"
  (tableSettings)="handleTableSettings($event)"
  (filters)="handleSelectedFilters($event)"
>
  <div class="service">
    <div class="indicators">
      <hrt-progress-indicator-svg
        [className]="'Normal'"
        [progressPercent]="okPercent"
        [circleColor]="'rgb(21,189,94)'"
        [progressColor]="'rgb(16,124,60)'"
        [count]="conditionObj.ok.count"
        [statusText]="conditionObj.ok.text"
        [statusTextColor]="'rgb(16,124,60)'"
        (svgClicked)="filterDashboard($event)"
      ></hrt-progress-indicator-svg>
      <hrt-progress-indicator-svg
        [className]="'Warning'"
        [progressPercent]="warningPercent"
        [circleColor]="'rgb(255,221,45)'"
        [progressColor]="'rgb(242,203,0)'"
        [count]="conditionObj.warning.count"
        [statusText]="conditionObj.warning.text"
        [statusTextColor]="'rgb(242,203,0)'"
        (svgClicked)="filterDashboard($event)"
      ></hrt-progress-indicator-svg>
      <hrt-progress-indicator-svg
        [className]="'Shutdown'"
        [progressPercent]="seriousPercent"
        [circleColor]="'rgb(252,72,83)'"
        [progressColor]="'rgb(166,25,32)'"
        [count]="conditionObj.serious.count"
        [statusText]="conditionObj.serious.text"
        [statusTextColor]="'rgb(166,25,32)'"
        (svgClicked)="filterDashboard($event)"
      ></hrt-progress-indicator-svg>
    </div>
    <mat-icon
      *ngIf="this.showStateMap"
      (click)="backToWorld()"
      class="MapButton custom-svg"
      matTooltip="Back to World View"
      [matTooltipPosition]="'left'"
      >public</mat-icon
    >
    <div class="geo-chart-container">
      <geo-chart
        *ngIf="this.showWorldMap"
        [Data]="this.mapData"
        [Options]="this.worldOptions"
        (RegionClicked)="regionSelected($event)"
      ></geo-chart>
      <geo-chart
        *ngIf="this.showStateMap"
        [Data]="this.mapData"
        [Options]="this.stateOptions"
        (RegionClicked)="regionSelected($event)"
      ></geo-chart>
    </div>
  </div>
</hrt-table>
