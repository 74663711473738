import { Observable, of } from 'rxjs';
import { ApiService } from '../api.service';
import { DatabricksStream, EventHubStream } from '../../data/data.generated';
import { mockDbsResp } from '../../mocks/MockDbsResp';

export class IOT {
  panelFields: string[] = [
    'ambient_Air_Temperature_1',
    'cPU_Temperature',
    'loop_Time',
    'system_Annunciation_Status',
    'Time',
  ];

  constructor(private _api: ApiService) {}

  public GetDeviceInformationFromInflux(
    pk: string
  ): Observable<EventHubStream> {
    var params: any = {
      DeviceId: pk,
    };

    return this._api.Get<EventHubStream>('GetDeviceInformation', params);
  }

  public GetDeviceInformationFromDbs(
    pk: string,
    isChartData?: boolean,
    signalName?: string
  ): Observable<DatabricksStream> {
    var params: any = {
      DeviceId: pk,
      Chart: isChartData,
      SignalName: signalName,
    };
    //return of(this.mockResp);
    return this._api.Get<DatabricksStream>(
      'GetDeviceInformationFromDbs',
      params
    );
  }

  public GetFluxSocketURL(hubName: string): Observable<string> {
    return this._api.Get<string>('GetFluxSocketURL', { HubName: hubName });
  }

  /**
   * Process the output from stream format to a JSON for single deviceId.
   * @param resp
   */
  processDbsStreamForSingleDevice(resp: DatabricksStream) {
    let deviceMap: Record<string, Record<string, string>> = {};
    let resultMap: any = {};
    resp.manifest?.schema.columns.map((col) => {
      // if col.name does not belong to panel fields.
      if (!this.panelFields.includes(col.name)) {
        // Not a panel field
        resultMap[col.name] = resp.result?.data_array[0][col.position];
      } else {
        // is a panel field.
        resultMap[col.name] = resp.result?.data_array[1][col.position];
      }
    });

    const pk = resultMap['pk'];
    deviceMap[pk] = resultMap;

    return deviceMap;
  }

  /**
   * Process the output from stream format to a JSON for multiple deviceId.
   * @param resp
   * @returns
   */
  processDbsStreamForMultiDevice(resp: DatabricksStream) {
    let deviceMap: Record<string, Record<string, string>> = {};

    resp.result?.data_array.forEach((colRes: (string | null)[], i: number) => {
      let pk: string = colRes[1] ?? '0';
      let resultMap = deviceMap[pk] || {};
      colRes.forEach((colVal: string | null, k: number) => {
        const fieldName = resp.manifest?.schema.columns[k].name;

        if (colVal != null && fieldName) {
          resultMap[fieldName] = colVal;
        }
      });
      deviceMap[pk] = resultMap;
    });

    return deviceMap;
  }

  processDbsChartData(resp: DatabricksStream, signalName: string) {
    const schemaMap: any = {};
    let datapoints: number[] = [];
    let timestamps: string[] = [];

    resp.manifest?.schema.columns.forEach((col) => {
      schemaMap[col.name] = col.position;
    });

    resp.result?.data_array.forEach((dataRow) => {
      datapoints.push(parseFloat(dataRow[schemaMap['value_analog']] || '0'));
      timestamps.push(dataRow[schemaMap['last_timestamp']] || '');
    });

    return {
      fieldName: signalName,
      datapoints: datapoints,
      timestamps: timestamps,
    };
  }
}
