<hrt-table
  [tableId]="tableId"
  [columnDefinitions]="columnDefinitions"
  [defaultTableSettings]="defaultTableConfig"
  [presetFilters]="presetFilter"
  [tableData]="tableData"
  [totalCount]="totalCount"
  [hasExport]="true"
  (exportClicked)="exportIotAssets()"
  (searchText)="updateSearchTerm($event)"
  (rowClicked)="rowClicked($event)"
  (tableSettings)="handleTableSettings($event)"
  (filters)="handleSelectedFilters($event)"
>
  <mat-icon
    *ngIf="this.showStateMap"
    (click)="backToWorld()"
    class="MapButton custom-svg"
    matTooltip="Back to World View"
    [matTooltipPosition]="'left'"
    >public</mat-icon
  >
  <div class="geo-chart-container">
    <geo-chart
      *ngIf="this.showWorldMap"
      [Data]="this.mapData"
      [Options]="this.worldOptions"
      (RegionClicked)="regionSelected($event)"
    ></geo-chart>
    <geo-chart
      *ngIf="this.showStateMap"
      [Data]="this.mapData"
      [Options]="this.stateOptions"
      (RegionClicked)="regionSelected($event)"
    ></geo-chart>
  </div>
</hrt-table>
