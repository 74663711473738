<hrt-table
  [tableId]="tableId"
  [columnDefinitions]="columnDefinitions"
  [defaultTableSettings]="defaultTableConfig"
  [presetFilters]="presetFilter"
  [tableData]="tableData"
  [totalCount]="totalCount"
  [hasExport]="true"
  (exportClicked)="exportIotAssets()"
  (searchText)="updateSearchTerm($event)"
  (rowClicked)="rowClicked($event)"
  (tableSettings)="handleTableSettings($event)"
  (filters)="handleSelectedFilters($event)"
>
  <div class="iot-content">
    <div class="indicators">
      <hrt-progress-indicator-svg
        [className]="'normal-iot'"
        [progressPercent]="normalPercent"
        [circleColor]="'rgb(21,189,94)'"
        [progressColor]="'rgb(16,124,60)'"
        [count]="normalCount"
        [statusText]="'Normal'"
        [statusTextColor]="'rgb(16,124,60)'"
        (svgClicked)="filterDashboard($event)"
      ></hrt-progress-indicator-svg>
      <hrt-progress-indicator-svg
        [className]="'warning-iot'"
        [progressPercent]="warningPercent"
        [circleColor]="'rgb(255,221,45)'"
        [progressColor]="'rgb(242,203,0)'"
        [count]="warningCount"
        [statusText]="'Warning'"
        [statusTextColor]="'rgb(242,203,0)'"
        (svgClicked)="filterDashboard($event)"
      ></hrt-progress-indicator-svg>
      <hrt-progress-indicator-svg
        [className]="'shutdown-iot'"
        [progressPercent]="shutdownPercent"
        [circleColor]="'rgb(252,72,83)'"
        [progressColor]="'rgb(166,25,32)'"
        [count]="shutdownCount"
        [statusText]="'Shutdown'"
        [statusTextColor]="'rgb(166,25,32)'"
        (svgClicked)="filterDashboard($event)"
      ></hrt-progress-indicator-svg>
    </div>
    <mat-icon
      *ngIf="this.showStateMap"
      (click)="backToWorld()"
      class="MapButton custom-svg"
      matTooltip="Back to World View"
      [matTooltipPosition]="'left'"
      >public</mat-icon
    >
    <div class="geo-chart-container">
        <geo-chart
          *ngIf="this.showWorldMap"
          [Data]="this.mapData"
          [Options]="this.worldOptions"
          (RegionClicked)="regionSelected($event)"
        ></geo-chart>
        <geo-chart
          *ngIf="this.showStateMap"
          [Data]="this.mapData"
          [Options]="this.stateOptions"
          (RegionClicked)="regionSelected($event)"
        ></geo-chart>
    </div>
  </div>
</hrt-table>
