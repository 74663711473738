import { Role } from "./roles";

export interface IVersioned {
  VersionNumber: number;
  VersionActive: boolean;
  VersionDraft: boolean;
  VersionDate: string;
  VersionUserId: number;
  VersionUserName: string;
  VersionComment: string;
}

export class Asset {
  Id: number = 0;
  ParentAssetId: number = 0;
  MainSiteId: number = 0;
  AssetTypeId: number = 0;
  ProductTypeId: number = 0;
  SystemId: number = 0;
  GeaReferenceName: string = "";
  CustomerReferenceName: string = "";
  Manufacturer: string = "";
  Model: string = "";
  AlternativeModel: string = "";
  SerialNumber: string = "";
  AlternativeSerialNumber: string = "";
  SalesOrderNumber: string = "";
  PurchaseOrderNumber: string = "";
  CustomerPurchaseOrderNumber: string = "";
  ManufactureDate: string = "";
  InstallDate: string = "";
  Active: boolean = false;
  ExternalId: string = "";
  GeaCompanyId: number = 0;
  Notes: string = "";
  ConnectionId: string = "";
}
export class AssetWithControlPanel {
  Asset: Asset = new Asset();
  ControlPanel: Asset = new Asset();
  Comments: CommentView[] = [];
}
export class AssetAttribute {
  Id: number = 0;
  AssetId: number = 0;
  Group: string = "";
  SubGroup: string = "";
  Label: string = "";
  Value: string = "";
  MeasurementId: number = 0;
  UnitId: number = 0;
}
export class AssetItem {
  Id: number = 0;
  AssetId: number = 0;
  Number: string = "";
  Quantity: number = 0;
  ImportLineNumber: number = 0;
  ImportCategory: string = "";
  ImportName: string = "";
  ImportDescription: string = "";
}
export class AssetDocument {
  Id: number = 0;
  AssetId: number = 0;
  Name: string = '';
  FileName: string = '';
}
export class AssetDrawing {
  Id: number = 0;
  AssetId: number = 0;
  Name: string = '';
  FileName: string = '';
}
export class AssetItemView {
  Id: number = 0;
  AssetId: number = 0;
  Number: string = "";
  Quantity: number = 0;
  LineNumber: number = 0;
  Name: string = "";
  Description: string = "";
  Category: string = "";
}
export class AssetSerialJobNumber {
  Id: number = 0;
  Manufacturer: string = "";
  SerialNumber: string = "";
  JobNumber: string = "";
}
export class AssetServiceRecord {
  Id: number = 0;
  AssetId: number = 0;
  Date: string = "";
  Technician: string = "";
  TypeId: number = 2;
  VisitNotes: string = "";
  ServiceNotes: string = "";
  ConditionId: number = 0;
  M1Id: number = 0;
  M2Id: number = 0;
  C1Id: number = 0;
  C2Id: number = 0;
  C3Id: number = 0;
  C4Id: number = 0;
  ReportTypeId: number = 0;
  ReportLocation: string = "";
  HasReport: boolean = false;
  HasStorageReport: boolean = false;
  ExternalId: string = "";
  ServiceCompany: string = "";
  ServiceOrderNumber: string = "";
  SiteContact: string = "";
  ReferenceName: string = "";
}
export class AssetServiceRecordAttribute {
  Id: number = 0;
  AssetServiceRecordId: number = 0;
  Label: string = "";
  Value: string = "";
  MeasurementId: number = 0;
  UnitId: number = 0;
}
export class AssetServiceRecordDashboardView {
  Id: number = 0;
  AssetId: number = 0;
  Date: string = "";
  TypeId: number = 0;
  VisitNotes: string = "";
  ServiceNotes: string = "";
  ConditionId: number = 0;
  ReportTypeId: number = 0;
  ReportLocation: string = "";
  HasReport: boolean = false;
  HasStorageReport: boolean = false;
  ProductTypeId: number = 0;
  State: string = "";
  Country: string = "";
  SiteName: string = "";
  Location: string = "";
}
export class Company {
  Id: number = 0;
  Name: string = "";
}
export class CompanyAsset {
  Id: number = 0;
  CompanyId: number = 0;
  AssetId: number = 0;
}
export class CompanySite {
  Id: number = 0;
  CompanyId: number = 0;
  SiteId: number = 0;
}
export class DashboardAssetView {
  Id: number = 0;
  MainSiteId: number = 0;
  ProductTypeId: number = 0;
  GeaReferenceName: string = "";
  Manufacturer: string = "";
  Model: string = "";
  SerialNumber: string = "";
  LocationName: string = "";
  LocationAddress: string = "";
  LocationState: string = "";
  LocationCountry: string = "";
  MotorHp: number = 0;
  InspectionCondition: number = 0;
  VibrationCondition: number = 0;
}
export class Document {
  Id: number = 0;
  DocumentId: string = "";
  ParentId: string = "";
  Title: string = "";
  EditorDependenciesLink: string = "";
  OriginalFileName: string = "";
  Version: number = 0;
  Keywords: string = "";
  CompanyId: number = 0;
  Language: string = "";
  FileType: string = "";
  DocumentType: string = "";
  ProductFamily: string = "";
  Size: number = 0;
  UseageRights: string = "";
  PreviewUrl: string = "";
  AreaOfUse: string = "";
}
export class Feedback {
  Id: number = 0;
  UserId: number = 0;
  Created: string = "";
  LastModifiedUserId: number = 0;
  LastModified: string = "";
  ApplicationId: string = "";
  ApplicationIds: number[] = [];
  CompanyId: number[]= [];
  TypeId: number = 0;
  StatusId: number = 0;
  PriorityId: number;
  Title: string = "";
  Text: string = "";
  Labels: string = "";
  Source: string = "";
  Program: string = "";
  Version: string = "";
  InternalOnly: boolean = false;
  PmDiscussion: boolean = false;

  [key: string]: any;
}

// Class made to delete feedbackView keys.
export class FeedbackCopy extends Feedback {
  override ApplicationId: string = ""; 
  override CompanyId: number[] = []; 
  override TypeId: number = 0; 
  override PriorityId: number = 0;
}

export class FeedbackAdmin {
  Id: number = 0;
  UserId: number = 0;
  FeedbackApplicationId: number = 0;
  EmailNewFeedback: boolean = false;
}
export class EnumDropdown {
  Id: number = 0;
  Name: string = "";
}
export class FeedbackAttachment {
  Id: number = 0;
  FeedbackId: number = 0;
  FileName: string = "";
  Size: number = 0;
	Created: string = "";
}

export class FeedbackAttachmentView extends FeedbackAttachment {
    UserName: string = "";
}
export class FeedbackComment {
  Id: number = 0;
  FeedbackId: number = 0;
  UserId: number = 0;
  Created: string = "";
  Text: string = "";
}
export class FeedbackCommentView extends FeedbackComment {
  UserName: string = "";
}

export class Bookmark {
  Id: number = 0;
  URL: string = "";
  BookmarkName: string = "";
  UserId: number = 0;
  ForeignKey: number = 0;
  BookmarkType: string = "";
}

export class Comment {
  Id: number = 0;
  CommentType: string = "";
  ForeignKey: number = 0;
  UserId: number = 0;
  Created: string = "";
  Text: string = "";
}


export class CommentView extends Comment {
  UserName: string = "";
}
export class FeedbackView extends Feedback {
  UserName: string = "";
  LastModifiedUserName: string = "";
  AttachmentCount: number = 0;
  CommentCount: number = 0;
  PreviewText: string = "";
  Type: string = "";
  CompanyName: string = "";
  CompanyIds: string = "";
  CompanyNames: string = "";
  Status: string = "";
  Priority: string = "";
}
export class FeedbackViewResult {
    Feedback: FeedbackView = new FeedbackView();
    Attachments: FeedbackAttachmentView[] = [];
    Comments: CommentView[] = [];
}
export class History {
  Id: number = 0;
  TableName: string = "";
  Time: string = "";
  Source: string = "";
  UserId: number = 0;
  ReferenceKey1: string = "";
  ReferenceKey2: string = "";
  ReferenceKey3: string = "";
  Description: string = "";
  ExtraDetails: string = "";
  Reference1Id: number = 0;
  Reference1Table: string = "";
  Reference2Id: number = 0;
  Reference2Table: string = "";
}
export class HourlyRate {
  Id: number = 0;
  HourlyRateSetId: number = 0;
  Name: string = "";
  Rate: number = 0;
}
export class HourlyRateSet implements IVersioned {
  Id: number = 0;
  CompanyId: number = 0;
  VersionNumber: number = 0;
  VersionActive: boolean = false;
  VersionDraft: boolean = false;
  VersionDate: string = "";
  VersionUserId: number = 0;
  VersionUserName: string = "";
  VersionComment: string = "";
}
export class Item {
  Id: number = 0;
  Number: string = "";
  Name: string = "";
  Description: string = "";
  PoolId: string = "";
  PoolName: string = "";
  Warehouse: string = "";
  ListPrice: number = 0;
}
export class MasterScheduleItem {
  Id: number = 0;
  AxId: string = "";
  SalesRep: string = "";
  Project: string = "";
  EndUser: string = "";
  City: string = "";
  State: string = "";
  Country: string = "";
  ContractValue: number = 0;
  Customer: string = "";
  AccountNumber: string = "";
  CustomerPo: string = "";
  PaymentTermsID: string = "";
  ItemDescription: string = "";
  TurnInDate: string = "";
  RequestedShipDate: string = "";
  CurrentShipDate: string = "";
  ApprovalDrawingDate: string = "";
  SalesManager: string = "";
  ProjectManager: string = "";
  ProjectManager2: string = "";
  Planner: string = "";
  ShipmentInformation: string = "";
  AcknowledgedShipDate: string = "";
  PreliminaryDrawingDate: string = "";
  MechanicalReleaseDate: string = "";
  VesselProductionDate: string = "";
  MechanicalProductionDate: string = "";
  ElectricalReleaseDate: string = "";
  ProgramDate: string = "";
  PanelUploadDate: string = "";
}
export class Note {
  Id: number = 0;
  UserId: number = 0;
  Username: string = "";
  ReferenceId: number = 0;
  ReferenceTypeId: number = 0;
  Timestamp: string = "";
  Text: string = "";
  MinUserTypeId: number = 0;
}
export class OrderStatusItem {
  Id: number = 0;
  SalesRep: string = "";
  Project: string = "";
  EndUser: string = "";
  City: string = "";
  State: string = "";
  Country: string = "";
  ContractValue: number = 0;
  Customer: string = "";
  AccountNumber: string = "";
  CustomerPo: string = "";
  PaymentTermsID: string = "";
  ItemDescription: string = "";
  TurnInDate: string = "";
  RequestedShipDate: string = "";
  CurrentShipDate: string = "";
  ApprovalDrawingDate: string = "";
  SalesManager: string = "";
  ProjectManager: string = "";
  ProjectManager2: string = "";
  Planner: string = "";
  ShipmentInformation: string = "";
  AcknowledgedShipDate: string = "";
}
export class ProductInfo implements IVersioned {
  Id: number = 0;
  ProductId: number = 0;
  InfoHtml: string = "";
  VersionNumber: number = 0;
  VersionActive: boolean = false;
  VersionDraft: boolean = false;
  VersionDate: string = "";
  VersionUserId: number = 0;
  VersionUserName: string = "";
  VersionComment: string = "";
}
export class ProductPrice implements IVersioned {
  Id: number = 0;
  ProductId: number = 0;
  CompanyId: number = 0;
  Group: string = "";
  Type: string = "";
  Key: string = "";
  SourceKey: string = "";
  Description: string = "";
  Comments: string = "";
  TotalCost: number = 0;
  ListPrice: number = 0;
  TransferPrice: number = 0;
  BomNumber: string = "";
  DerivedPrice: string = "";
  UseHourlyRates: boolean = false;
  IsCustom: boolean = false;
  HourlyRateSetId: number = 0;
  VersionNumber: number = 0;
  VersionActive: boolean = false;
  VersionDraft: boolean = false;
  VersionDate: string = "";
  VersionUserId: number = 0;
  VersionUserName: string = "";
  VersionComment: string = "";
}
export class Permission {
  Id: number = 0;
  UserId: number = 0;
  PermissionTypeId: number = 0;
  CompanyId: number = 0;
  SiteId: number = 0;
  AssetId: number = 0;
}
export class Project {
  Id: number = 0;
  SiteId: number = 0;
  AccountNumber: string = "";
  Name: string = "";
  Number: string = "";
  FullNumber: string = "";
  DiscountKeyId: number = 0;
  CreatedDate: string = "";
}
export class Site {
  Id: number = 0;
  MainCompanyId: number = 0;
  Name: string = "";
  Address: string = "";
  City: string = "";
  State: string = "";
  Zip: string = "";
  Country: string = "";
  Normalized: string = "";
  Latitude: number = 0;
  Longitude: number = 0;
  AxAccountNumbers: string = "";
  ProjectNumbers: string = "";
  SiteTypeId: number = 0;
  ExternalId: string = "";
}
export class System {
  Id: number = 0;
  SiteId: number = 0;
  Name: string = "";
  Description: string = "";
}
export class User {
  Id: number = 0;
  CompanyId: number = 1;
  SiteId: number = 0;
  Name: string = "";
  Company: string = "";
  Address: string = "";
  City: string = "";
  State: string = "";
  Zip: string = "";
  Country: string = "";
  Email: string = "";
  Phone: string = "";
  UserTypeId: number = 0;
  RoleId: number = 0;
  OrderStatusFilters: string = "";
  SiteFilter: string = "";
  Password: string = "";
  LastModified: string = "";
  LastModifiedByUserId: number = 0;
  GeaIdUserId: string = "";
  GeaIdRegistration: string = "";
  GeaIdMemberships: string = "";
  GeaIdLastModified: string = "";
  GeaIdCustomerNumbers: string = "";
  IsTemplate: boolean = false;
}
export class UserView {
  Id: number = 0;
  CompanyId: number = 1;
  SiteId: number = 0;
  Name: string = "";
  Company: string = "";
  Address: string = "";
  City: string = "";
  State: string = "";
  Zip: string = "";
  Country: string = "";
  Email: string = "";
  Phone: string = "";
  UserTypeId: number = 0;
  RoleId: number = 0;
  OrderStatusFilters: string = "";
  SiteFilter: string = "";
  Password: string = "";
  LastModified: string = "";
  LastModifiedByUserId: number = 0;
  GeaIdUserId: string = "";
  GeaIdRegistration: string = "";
  GeaIdMemberships: string = "";
  GeaIdLastModified: string = "";
  GeaIdCustomerNumbers: string = "";
  IsTemplate: boolean = false;
  LastModifiedByUserName: string = "";
  LastLogin: string = "";
}
export class UserApplicationFeature {
  Id: number = 0;
  UserId: number = 0;
  FeatureId: number = 0;
  CanView: boolean = false;
  CanEdit: boolean = false;
}
export class UserApplicationFeaturesWithRoleId {
  RoleId: number;
  CustomerNumbers: string;
  Features: UserApplicationFeature[];
}
export class UserLogin {
  Id: number = 0;
  UserId: number = 0;
  LoginTime: string = "";
  ExpirationTime: string = "";
  Token: string = "";
}
export class UserNotification {
  Id: number = 0;
  UserId: number = 0;
  TypeId: number = 0;
  ForeignKey: number = 0;
}

export class EventHubStream {
  equipment: {
    panel: Panel;
    compressor1: Compressor;
  }
  pk: string;
  ipAddress: string;
  sourceTimestamp: string;
  messageType: 'Data';
}

export interface DatabricksStream {
  statement_id: string,
  status: {
    state: "SUCCEEDED" | "FAILED",
    error?: {
      error_code: string,
      message: string,
    }
  },
  manifest?: {
    chunks: DbsChunks[],
    format: string, // "JSON_ARRAY"
    schema: DbsSchema,
    total_chunk_count: number,
    total_row_count: number,
    truncated: boolean
  },
  result?: {
    chunk_index: number,
    data_array: (string | null )[][]
  }
}

export interface DbsChunks {
  chunk_index: number,
  row_count: number,
  row_offset: number
}

export interface DbsSchema {
  column_count: number,
  columns: {
    name: string,
    position: number,
    type_name: string,
    type_text: string
  }[]
}

export class FluxHubStream {
  [key: string]: keyof (Compressor | Panel | EventHubStream);
}

export class Panel {
  loop_time: number = 0;
  cPU_Temperature: number = 0;
  system_Annunciation_Status: number = 0;
  ambient_Air_Temperature_1: number = 0;
}

export class Compressor {
  type: number = 0;
  capacity_Step_Percentage: number = 0;
  suction_Pressure: number = 0;
  discharge_Pressure: number = 0;
  oil_Pressure: number = 0;
  discharge_Temperature: number = 0;
  oil_Temperature: number = 0;
  oil_Separator_Temperature: number = 0;
  oil_Differential: number = 0;
  oil_Filter_Differential: number = 0;
  saturated_Suction_Temperature: number = 0;
  saturated_Discharge_Temperature: number = 0;
  suction_Superheat: number = 0;
  discharge_Superheat: number = 0;
  calculated_Zero_Load_Position: number = 0;
  adjusted_Zero_Load_Position: number = 0;
  current_Control_Signal: number = 0;
  current_Control_Mode: number = 0;
  current_Capacity_Mode: number = 0;
  motor_Running: boolean = false;
  motor_Speed: number = 0;
  motor_Current: number = 0;
  number_Of_Starts: number = 0;
  slide_Position: number = 0;
  compressor_RunTime_Hours: number = 0;
  kWH_Total_Usage_Hours: number = 0;
  kWh_Demand: number = 0;
  capacity: number = 0;
  remote_Start_Stop_Monitoring: boolean = false;
  limitations_Active: number = 0;
  cleared_Drive_Timers: boolean = false;
  cleared_Shutdown: boolean = false;
  compressor_Annunciation_Status: number = 0;
  capacity_Signal: number = 0;
  start_Requested: number = 0;
}

export class ConfigList {
  Id: number;
  ConfigName: string;
  ConfigValue: string;
  isUIConfig: boolean;
}

export class ConfigData {
  Id: number;
  ConfigListId: number;
  Target? : string;
  key: string;
  value: string;
  ConfigListValue: string;
}
