import { Component, OnDestroy, OnInit, ViewEncapsulation, effect } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Asset,
  AssetDocument,
  AssetDrawing,
  CommentView,
  EventHubStream,
  FluxHubStream,
} from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import {
  GetCustomTypeIcon,
  GetCustomTypeText,
  GetProductTypeIcon,
} from 'src/app/shared/data/product-types';
import notify from 'devextreme/ui/notify';
import { WebsocketService } from 'src/app/shared/services/websocket.service';
import { TabData } from 'src/app/shared/components/hrt-tab/hrt-tab.component';
import { AssetServiceDataComponent } from './asset-service-data/asset-service-data.component';
import { AssetGenericDataComponent } from './asset-generic-data/asset-generic-data.component';
import { AssetDocumentationComponent } from './asset-documentation/asset-documentation.component';
import { AssetComponentListComponent } from './asset-component-list/asset-component-list.component';
import { AssetNotesComponent } from './asset-notes/asset-notes.component';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { HrtBookmarkComponent } from 'src/app/shared/components/hrt-bookmark/hrt-bookmark.component';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetDetailsComponent implements OnInit, OnDestroy {
  public id: number = -1;
  public asset: Asset | undefined = undefined;

  public title: string = '';
  public projectNumber: string = '';
  public notes: string = '';
  public notesLoaded: boolean = false;
  public isOmni = false;
  public isConnectedAsset: boolean = false;
  public productTypeId: number = 0;
  public mainSiteId: number = 0;
  public externalId: string = '';
  public realTimeData: FluxHubStream;
  public fetchingOmniData: boolean = false;
  public pk: string = '';
  public selectedParentTabIndex: number = 0;
  public selectedIndex: number = 0;
  public socketInterval: any;
  public selectedProduct: number = 106;
  public comments: CommentView[] = [];
  public commentsData: CommentView[] = [];
  public commentType: string = "Asset";
  public url: any;
  public bookmarkName : any;
  public bookmarkType : string = "Asset";
  public isBookmarked : boolean;
  public link: any;

  public labelValueColumns: string[] = ['label', 'value'];

  public technicalData: any[] = [];
  public asBuiltData: any[] = [];
  public drawingData: any[] = [];
  public documentList: AssetDocument[] = [];
  public panelData: AssetDocument[] = [];

  public getCustomTypeText = GetCustomTypeText;
  public getCustomTypeIcon = GetCustomTypeIcon;
  public getProductTypeIcon = GetProductTypeIcon;

  constructor(
    private api: ApiService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private websocketService: WebsocketService,
    private loader: LoaderService,
    public dialog: MatDialog,
  ) {}

  assetDetailTabs: TabData[] = [

    {
      tabName: 'Service',
      tabComponent: AssetServiceDataComponent,
      isTabVisible : true,
      data: {
        assetId: this.id
      }
    },
    {
      tabName: 'Documents',
      tabComponent: AssetDocumentationComponent,
      isTabVisible : true,
    },
    {
      tabName: 'Components',
      tabComponent: AssetComponentListComponent,
      isTabVisible : true,
      data: {
        assetId: this.id
      }
    },
    {
      tabName: 'Notes',
      tabComponent: AssetNotesComponent,
      isTabVisible : true,
    },
  ];

  ngOnInit(): void {
    this.route.params.subscribe((p: any) => {
      if (p.realTime) {
        // control coming from click of Annunciation status in assets.
        this.selectedParentTabIndex = 1;
      }
      if (p.service) {
        this.selectedParentTabIndex = 2;
      }
      this.id = Number.parseFloat(p['id']);
      this.loader.showLoadingIcon();
      this.api.Assets.GetAssetDetails(this.id).subscribe((x) => {
        this.asset = x.Asset;
        this.notes = x.Asset.Notes;
        this.comments = x.Comments;
        this.notesLoaded = true;
        this.productTypeId = x.Asset.ProductTypeId;
        this.mainSiteId = x.Asset.MainSiteId;
        this.comments = this.comments?.reverse();
      
        this.externalId = (x.ControlPanel ? x.ControlPanel.ExternalId : x.Asset.ExternalId);
        if (this.externalId) {
          this.pk = this.externalId.split('-')[1];
          try {
            this.isConnectedAsset = this.websocketService.connectedAssets.includes(this.pk);
            if (this.isConnectedAsset) {
              const messageStr = JSON.stringify({
                deviceList: this.pk
              });
              this.websocketService.send(messageStr);
              this.socketInterval = setInterval(() => {
                this.websocketService.send(messageStr);
              }, 10000);
            } 
          }
          catch (error) {
            // Prevents whole page crashing when web socket cannot connect
            console.log(error);
          }
          this.selectedIndex = this.isConnectedAsset ? 1: 0;
          this.selectedParentTabIndex = this.isConnectedAsset ? 1: 0;
        }
        this.isOmni = this.getCustomTypeText(this.productTypeId) == 'Omni';

        this.technicalData.push({
          label: 'GEA Reference Name',
          value: x.Asset.GeaReferenceName,
        });
        this.technicalData.push({
          label: 'Customer Reference Name',
          value: x.Asset.CustomerReferenceName,
        });
        this.technicalData.push({
          label: 'Manufacturer',
          value: x.Asset.Manufacturer,
        });
        this.technicalData.push({ label: 'Model', value: x.Asset.Model });
        this.technicalData.push({
          label: 'Alternative Model',
          value: x.Asset.AlternativeModel,
        });
        this.technicalData.push({
          label: 'Serial Number',
          value: x.Asset.SerialNumber,
        });
        this.technicalData.push({
          label: 'Alternative Serial Number',
          value: x.Asset.AlternativeSerialNumber,
        });
        this.technicalData.push({
          label: 'Sales Order Number',
          value: x.Asset.SalesOrderNumber,
        });
        this.technicalData.push({
          label: 'Purchase Order Number',
          value: x.Asset.PurchaseOrderNumber,
        });
        this.sortAttributes();

        if (x.Asset.Model) this.title += x.Asset.Model + ' ';
        if (x.Asset.AlternativeModel) this.title += x.Asset.AlternativeModel + ' ';
        if (x.Asset.GeaReferenceName) this.title += x.Asset.GeaReferenceName + ' ';
        if (x.Asset.SalesOrderNumber) this.projectNumber += x.Asset.SalesOrderNumber;
        this.title = this.title.trim();

        if (this.isConnectedAsset) {
          this.isConnectedAsset = true;
          this.fetchingOmniData = true;
          this.websocketService.liveData.subscribe((deviceData) => {
            if (deviceData[this.pk]) {
              this.realTimeData = deviceData[this.pk];
              this.fetchingOmniData = false;
            }
          });
        }

        // Get drawings and documentations data.
        this.api.Assets.GetAssetDocumentations(this.id).subscribe((x) => {
          this.panelData = x;
          if (this.isOmni) {
            this.appendToOmniDocs(x);
            return;
          }
          this.documentList = x;
        });
        this.api.Assets.GetAssetDrawings(this.id).subscribe((x) => {
          this.drawingData = x;
        });
        this.loader.hideLoadingIcon();
      });

      this.api.Assets.GetAssetAttributes(this.id).subscribe((x) => {
        for (var i = 0; i < x.length; i++) {
          if (x[i].Group === 'AsBuilt') {
            this.asBuiltData.push({ label: x[i].Label, value: x[i].Value });
          } else if (x[i].Group === 'Drawing') {
            // only allowed drawings for those cutom projects.
            // this.drawingData.push({ label: x[i].Label, value: x[i].Value });
          } else {
            this.technicalData.push({ label: x[i].Label, value: x[i].Value });
          }
        }
      });
    });
    const userBookmarks= JSON.parse(sessionStorage.getItem('UserBookmarks')||'');
    for(let i = 0; i < userBookmarks.length; i++){
      if(userBookmarks[i].ForeignKey === this.id && userBookmarks[i].BookmarkType === this.bookmarkType){
        this.isBookmarked=!this.isBookmarked
      }
    }
  }

  tabChange(tabIndex: number) {
    sessionStorage.setItem('asset-details-tab', JSON.stringify(tabIndex));
  }

  addComment(newComment: string) {
    if (newComment == '') return;
    this.api.Feedback.AddFeedbackComment(this.id, newComment, this.commentType).subscribe((x) => {
      this.refreshCommentData();
    });
  }

  toggleBookmark(){
    if(!this.isBookmarked){
      this.url = window.location.href;
      this.bookmarkName = this.title;
      const dialogRef = this.dialog.open(HrtBookmarkComponent, {
        data: {url: this.url, bookmarkName: this.bookmarkName}
      });

      dialogRef.afterClosed().subscribe(result => {
        this.bookmarkName = result;
        if(result) {
          this.api.Bookmark.AddBookmark(this.id, this.bookmarkType,this.url, this.bookmarkName).subscribe({
            next: (x) => {
            this.isBookmarked = !this.isBookmarked;
          },
          });
          let existingBookmarks = JSON.parse(sessionStorage.getItem('UserBookmarks') || '')
          existingBookmarks.push({ForeignKey: this.id, BookmarkType: this.bookmarkType})
          sessionStorage.setItem('UserBookmarks',JSON.stringify(existingBookmarks))
        }
      });  

    } else {
      this.api.Bookmark.DeleteBookmark(this.id, this.bookmarkType).subscribe({
        next: (x) => {
        this.isBookmarked = !this.isBookmarked;
      },
      });
      let existingBookmarks = JSON.parse(sessionStorage.getItem('UserBookmarks') || '');
      existingBookmarks = existingBookmarks.filter((bookmark: { ForeignKey: number; BookmarkType: string; }) => !(bookmark.ForeignKey === this.id && bookmark.BookmarkType ===this.bookmarkType))
      sessionStorage.setItem('UserBookmarks',JSON.stringify(existingBookmarks));
    }
  }

  copyLink(){
    this.link = window.location.href;
    navigator.clipboard.writeText(this.link);
  }

  refreshCommentData(){
    this.api.Assets.GetAssetDetails(this.id).subscribe((x) => {
      this.comments = [];
      this.comments = x.Comments;
      this.comments = this.comments.reverse();
    });
  }

  sortAttributes() {
    let order = [
      'Sales Order Number',
      'Purchase Order Number',
      'Main Site',
      'Manufacturer',
      'Model',
      'Alternative Model',
      'Serial Number',
      'Alternative Serial Number',
      'GEA Reference Name',
      'Customer Reference Name',
      'Compressor VI',
      'Refrigerant',
      'Application',
      'Oil Cooling',
      'Oil Type',
      'Year Of Manufacture',
      'Mark',
      'Motor Manufacturer',
      'Motor Serial Number',
      'Motor Power',
      'Motor Speed',
      'Oil Separator Manufacturer',
      'Oil Separator National Board Number',
      'Oil Separator Diameter',
      'Separator Size',
      'Oil Charge',
      'Oil Pump Manufacturer',
      'Oil Pump Serial Number',
      'Oil Pump Motor Serial Number',
      'Oil Pump Motor Speed',
      'Oil Pump Motor Power',
      'Control Panel Type',
      'Control Panel cUL Number',
      'Original Control Panel',

      'Capacity',
      'Power',
      'Suction Temperature',
      'Suction Pressure',
      'Discharge Temperature',
      'Discharge Pressure',

      'General Arrangement',
      'Piping Schematic',
      'Enclosure Assembly',
      'Control Diagram',
      'Motor Starter Connections Diagram',
    ];

    this.technicalData.sort(
      (x: any, y: any) =>
        (order.indexOf(x.label) == -1 ? 9999 : order.indexOf(x.label)) -
        (order.indexOf(y.label) == -1 ? 9999 : order.indexOf(y.label))
    );
    this.asBuiltData.sort(
      (x: any, y: any) =>
        (order.indexOf(x.label) == -1 ? 9999 : order.indexOf(x.label)) -
        (order.indexOf(y.label) == -1 ? 9999 : order.indexOf(y.label))
    );
    this.drawingData.sort(
      (x: any, y: any) =>
        (order.indexOf(x.label) == -1 ? 9999 : order.indexOf(x.label)) -
        (order.indexOf(y.label) == -1 ? 9999 : order.indexOf(y.label))
    );

    this.technicalData = [...this.technicalData];
    this.asBuiltData = [...this.asBuiltData];
    this.drawingData = [...this.drawingData];
  }

  appendToOmniDocs(docs: AssetDocument[]) {
    const generalOmniDocs: AssetDocument[] = [
      {
        Id: 0,
        AssetId: this.id,
        Name: 'Installation Manual',
        FileName: '803550_Installation Manual_en-US_1.pdf',
      },
      {
        Id: 0,
        AssetId: this.id,
        Name: 'Communication Guide',
        FileName: '805050_Communication Guide_GEA_Omni_en-US_14.pdf',
      },
      {
        Id: 0,
        AssetId: this.id,
        Name: 'Instruction Manual',
        FileName: '806550_Instruction Manual_GEA_Omni_en-US_6.pdf',
      },
      {
        Id: 0,
        AssetId: this.id,
        Name: 'Spare Parts',
        FileName: '807050_Spare Parts List_GEA_Omni_en-US_6.pdf',
      },
    ];
    this.documentList = generalOmniDocs.concat(docs);
  }

  openSite() {
    if (this.mainSiteId > 0) {
      this.router.navigate(['/site-details', { id: this.mainSiteId }]);
    } else {
      notify('No site exists for this order', 'error', 2000);
    }
    // api call to bring back
  }

  goBack(){
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/home']);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.socketInterval);
    sessionStorage.removeItem('asset-details-tab');
    //  this.websocketService.closeConnection();
  }
}
