import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import {
  Asset,
  AssetAttribute,
  AssetDocument,
  AssetDrawing,
  AssetItemView,
  AssetServiceRecord,
  AssetServiceRecordDashboardView,
  AssetWithControlPanel,
  History,
} from '../../data/data.generated';
import { TableResult, TableView } from './table';
import { number } from 'echarts';

export class Assets {
  constructor(private _api: ApiService) {}

  public GetAssets(
    listOptions: TableView,
    mainSiteId?: number,
    country?: string,
    state?: string,
    productTypeId?: number,
    fetchConnectedAssets?: boolean
  ): Observable<TableResult<Asset>> {
    var params: any = {
      ...listOptions,
    };
    if (mainSiteId) params.MainSiteId = mainSiteId;
    if (country) params.Country = country;
    if (state) params.State = state;
    if (productTypeId) params.ProductTypeId = productTypeId;
    if (fetchConnectedAssets)
      params.FetchConnectedAssets = fetchConnectedAssets;
    return this._api.Get<TableResult<Asset>>('GetAssets', params);
  }

  public GetAssetDocumentations(assetId: number): Observable<AssetDocument[]> {
    return this._api.Get<AssetDocument[]>('GetAssetDocumentations', {
      Id: assetId,
    });
  }

  public GetAssetDrawings(assetId: number): Observable<AssetDrawing[]> {
    return this._api.Get<AssetDrawing[]>('GetAssetDrawings', { Id: assetId });
  }

  public SearchAssets(
    listOptions: TableView,
    productTypeId?: number
  ): Observable<TableResult<Asset>> {
    var params: any = {
      ...listOptions,
    };
    if (productTypeId) params.ProductTypeId = productTypeId;
    return this._api.Get<TableResult<Asset>>('SearchAssets', params);
  }

  public GetAssetMapData(
    typeId?: number,
    country?: string,
    productTypeId?: number,
    conditionId?: number,
    fetchConnectedAssets?: boolean
  ): Observable<AssetMapData[]> {
    var params: any = {};
    if (typeId) params.TypeId = typeId;
    if (country) params.Country = country;
    if (productTypeId) params.ProductTypeId = productTypeId;
    if (conditionId) params.ConditionId = conditionId;
    if (fetchConnectedAssets)
      params.FetchConnectedAssets = fetchConnectedAssets;
    return this._api.Get<AssetMapData[]>('GetAssetMapData', params);
  }

  public GetAssetDetails(id: number): Observable<AssetWithControlPanel> {
    return this._api.Get<AssetWithControlPanel>('GetAssetDetails', { Id: id });
  }

  public GetAssetAttributes(id: number): Observable<AssetAttribute[]> {
    return this._api.Get<AssetAttribute[]>('GetAssetAttributes', { Id: id });
  }

  public GetAssetItems(
    listOptions: TableView,
    id: number
  ): Observable<TableResult<AssetItemView>> {
    return this._api.Get<TableResult<AssetItemView>>('GetAssetItems', {
      ...listOptions,
      AssetId: id,
    });
  }

  public GetAssetServiceRecords(
    listOptions: TableView,
    id: number
  ): Observable<TableResult<AssetServiceRecord>> {
    return this._api.Get<TableResult<AssetServiceRecord>>(
      'GetAssetServiceRecords',
      { ...listOptions, Id: id }
    );
  }

  public GetAssetServiceRecord(id: number): Observable<AssetServiceRecord> {
    return this._api.Get<AssetServiceRecord>("GetAssetServiceRecord", { Id: id });
  }

  public GetAssetServiceRecordDashboardViews(
    listOptions: TableView,
    country?: string,
    state?: string,
    productTypeId?: number,
    conditionId?: number
  ): Observable<TableResult<AssetServiceRecordDashboardView>> {
    var params: any = {
      ...listOptions,
    };
    if (country) params.Country = country;
    if (state) params.State = state;
    if (productTypeId) params.ProductTypeId = productTypeId;
    if (conditionId) params.ConditionId = conditionId;
    return this._api.Get<TableResult<AssetServiceRecordDashboardView>>(
      'GetAssetServiceRecordDashboardViews',
      params
    );
  }

  public GetAssetServiceConditionCount(
    country?: string
  ): Observable<AssetConditionCount> {
    var params: any = {};
    if (country) params.Country = country;
    return this._api.Get<AssetConditionCount>(
      'GetAssetServiceConditionCount',
      params
    );
  }

  public GetAssetHistory(listOptions: TableView, id: number) {
    return this._api.Get<TableResult<History>>('GetAssetHistory', {
      ...listOptions,
      Id: id,
    });
  }

  public CreateAsset(asset: Asset): Observable<string> {
    return this._api.Get<string>('CreateAsset', {
      Object: asset,
    });
  }

  public UpdateAsset(asset: Asset): Observable<string> {
    return this._api.Get<string>('UpdateAsset', {
      Object: asset,
    });
  }

  public UpdateAssetNotes(id: number, notes: string): Observable<string> {
    return this._api.Get<string>('UpdateAssetNotes', {
      Id: id,
      notes: notes,
    });
  }

  public DeleteAsset(id: number): Observable<string> {
    return this._api.Get<string>('DeleteAsset', {
      Id: id,
    });
  }

  public ExportAssets(
    listOptions: TableView,
    mainSiteId?: number,
    country?: string,
    state?: string,
    productTypeId?: number,
    fetchConnectedAssets?: boolean,
    tabName?: string
  ) {
    var params: any = {
      ...listOptions,
    };
    if (mainSiteId) params.MainSiteId = mainSiteId;
    if (country) params.Country = country;
    if (state) params.State = state;
    if (productTypeId) params.ProductTypeId = productTypeId;
    if (fetchConnectedAssets)
      params.FetchConnectedAssets = fetchConnectedAssets;

    let date = new Date().toLocaleString('en-US');

    this._api.Download(
      'ExportAssets',
      params,
      `${tabName} Export ${date}`,
      'text/csv',
      (err: string) => {}
    );
  }

  public ExportAssetService(
    listOptions: TableView,
    country?: string,
    state?: string,
    productTypeId?: number,
    conditionId?: number
  ) {
    var params: any = {
      ...listOptions,
    };
    if (country) params.Country = country;
    if (state) params.State = state;
    if (productTypeId) params.ProductTypeId = productTypeId;
    if (conditionId) params.ConditionId = conditionId;

    let date = new Date().toLocaleString('en-US');

    this._api.Download(
      'ExportAssetService',
      params,
      `Service Export ${date}`,
      'text/csv',
      (err: string) => {}
    );
  }

  public CreateAssetServiceRecord(
    serviceRecord: AssetServiceRecord,
    file: File | undefined
  ): Observable<string> {
    return this._api.Post(
      'CreateAssetServiceRecord',
      {
        Object: JSON.stringify(serviceRecord),
      },
      file ? [file] : []
    );
  }

  public UpdateAssetServiceRecord(
    serviceRecord: AssetServiceRecord,
    file: File | undefined
  ): Observable<string> {
    return this._api.Post(
      'UpdateAssetServiceRecord',
      {
        Object: JSON.stringify(serviceRecord),
      },
      file ? [file] : []
    );
  }

  public DeleteAssetServiceRecord(id: number): Observable<string> {
    return this._api.Get<string>('DeleteAssetServiceRecord', {
      Id: id,
    });
  }

  public DownloadAssetServiceRecordReport(record: AssetServiceRecord | AssetServiceRecordDashboardView) {
    this._api.Download(
      'DownloadAssetServiceRecordReport',
      {
        Id: record.Id,
      },
      record.ReportLocation,
      'application/pdf',
      (err: string) => {}
    );
  }

  public DownloadAssetDrawingsAndDocs(record: AssetDocument, filePath: string) {
    this._api.Download(
      'DownloadAssetDrawingsAndDocs',
      {
        Id: record.AssetId,
        FileName: record.Name,
        FilePath: filePath,
      },
      record.FileName,
      'application/pdf',
      (err: string) => {}
    );
  }
}

export interface AssetMapData {
  Area: string;
  Count: number;
}

export interface AssetConditionCount {
  counts: {
    ok: number;
    warning: number;
    serious: number;
  };
}
