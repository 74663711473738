import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { tap } from 'rxjs';
import { Asset, FluxHubStream, Site } from 'src/app/shared/data/data.generated';
import { HRTColumnDefinition } from 'src/app/shared/data/filterConfiguration';
import {
  GetCustomTypeIcon,
  GetCustomTypeIconWidth,
  GetCustomTypeText,
} from 'src/app/shared/data/product-types';
import { tableDefinitions } from 'src/app/shared/data/table-definitions';
import { ApiService } from 'src/app/shared/services/api.service';
import {
  Table,
  TableResult,
  TableView,
} from 'src/app/shared/services/api/table';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { environment } from 'src/environments';
import { WebsocketService } from 'src/app/shared/services/websocket.service';
import { Metadata } from 'src/app/shared/data/compressor-metadata';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { TableIconComponent } from 'src/app/shared/components/table-icon/table-icon.component';
import { HrtBookmarkComponent } from 'src/app/shared/components/hrt-bookmark/hrt-bookmark.component';
import { MatDialog } from '@angular/material/dialog';
import { HrtTableService } from 'src/app/shared/services/hrt-table.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss'],
})
export class SiteDetailsComponent {
  socketInterval: NodeJS.Timer;
  public id: number = -1;
  public site: Site | undefined = undefined;
  public siteData: any | undefined = undefined;
  public siteDisplayedColumns: string[] = ['name', 'value'];
  public displayedColumns: string[] = [
    'Icon',
    'Type',
    'ReferenceName',
    'Manufacturer',
    'Model',
    'AlternativeModel',
    'SerialNumber',
    'ProjectNumber',
    'Annunciation',
    'MotorStatus',
  ];
  public table: Table<Asset> = new Table<Asset>(this.loader);
  public getCustomTypeText = GetCustomTypeText;
  public getCustomTypeIcon = GetCustomTypeIcon;
  public getCustomTypeIconWidth = GetCustomTypeIconWidth;
  public isEnvironment: string | null = environment.name;
  public operatingStatusCards: Metadata[] = [];
  public motorStatusText: string;
  public pk: string = '';
  public isConnectedAsset: boolean = false;
  public externalIds: any;
  public data: FluxHubStream;
  public isSocketOpen: boolean = false;
  deviceData: any;
  annunStatuses: any = {};
  public url: any;
  public bookmarkName: any;
  public bookmarkType: string = 'Site';
  public isBookmark: boolean;

  tableId: string = 'sitesDetailsComponent';
  totalCount: number = 0;
  columnDefinitions: HRTColumnDefinition[];
  defaultTableConfig: FilterTableSettings = {
    pageSize: 20,
    columns: {},
  };
  tableData: any;
  listOptions: TableView = new TableView();
  assetTable: Table<Asset> = new Table<Asset>(this.loader);

  tableDataLoading: boolean = false;
  initialLoad: boolean = true;

  mapData: (string | number)[][] = [];
  mapDataLoading: boolean = false;

  public shutdownStatusMapping: any = {
    0: 'Normal',
    1: 'Warning',
    2: 'Shutdown',
  };

  constructor(
    private api: ApiService,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private websocketService: WebsocketService,
    private loader: LoaderService,
    private hrtTableService: HrtTableService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    //this.pingWebSocket();
    if (sessionStorage.getItem(this.tableId + '-listOptions')) {
      this.listOptions = JSON.parse(
        sessionStorage.getItem(this.tableId + '-listOptions') || ''
      );
    }
    this.route.params.subscribe((p: any) => {
      this.id = Number.parseFloat(p['id']);
      this.api.Sites.GetSiteDetails(this.id).subscribe((x) => {
        this.site = x;
        this.siteData = [
          // { name: "GEA Project Number", value: (this.site.ProjectNumbers ?? "").replace(/,/g, ', ') },
          { name: 'Name', value: this.site.Name },
          { name: 'Address', value: this.site.Address },
          { name: 'City', value: this.site.City },
          { name: 'State', value: this.site.State },
          { name: 'Zip', value: this.site.Zip },
          { name: 'Country', value: this.site.Country },
        ];
      });
      this.listOptions.SortColumn = 'ConnectionId';
      this.listOptions.SortDesc = 'true';
      this.assetTable.GetApi = (listOptions: TableView) => {
        return this.api.Assets.GetAssets(this.listOptions, this.id).pipe(
          tap((assetData: TableResult<Asset>) => {
            this.tableData = this.modifyTableData(assetData.Data);
            this.totalCount = assetData.Count;
          })
        );
      };

      this.table.GetApi = (listOptions: TableView) => {
        listOptions.SortColumn = 'ConnectionId';
        listOptions.SortDesc = 'true';
        return this.api.Assets.GetAssets(listOptions, this.id);
      };
      this.table.RefreshData();
    });
    const userBookmarks = JSON.parse(
      sessionStorage.getItem('UserBookmarks') ?? ''
    );
    for (let i = 0; i < userBookmarks.length; i++) {
      if (
        userBookmarks[i].ForeignKey == this.id &&
        userBookmarks[i].BookmarkType == this.bookmarkType
      ) {
        this.isBookmark = !this.isBookmark;
      }
    }

    // this.siteTable.Updated = () => (this.tableDataLoading = false);
    this.assignMockColDefinitions();
  }

  ngAfterViewInit() {
    this.initialLoad = false;
  }

  pingWebSocket() {
    const pkList: string = this.websocketService.connectedBuckets.join(',');
    const messageStr = JSON.stringify({
      deviceList: pkList,
    });
    this.socketInterval = setInterval(() => {
      this.websocketService.send(messageStr);
      this.isSocketOpen = true;
    }, 5000);
    this.websocketService.liveData.subscribe((dataReceived) => {
      if (dataReceived && Object.keys(dataReceived).length > 0) {
        this.deviceData = dataReceived;
      }
    });
  }

  assignMockColDefinitions() {
    let definitions = tableDefinitions.assetTableDef;

    this.columnDefinitions = this.hrtTableService.addTypeIconToColumn(
      definitions,
      ['ProductTypeId', 'OperatingStatus', 'MotorStatus'],
      TableIconComponent
    );
  }

  modifyTableData(assetData: any) {
    const modifiedAsset = assetData.map((asset: any) => {
      return {
        ...asset,
        ProductTypeIdText: this.getCustomTypeText(asset.ProductTypeId),
      };
    });
    return modifiedAsset;
  }

  openDetails(id: number) {
    this.router.navigate(['/asset-details', { id: id }]);
  }

  handleTableSettings(tableSettings: FilterTableSettings) {
    if (this.initialLoad) return;

    const oldOptions = JSON.parse(JSON.stringify(this.listOptions));

    const tableColKeys: string[] = Object.keys(tableSettings.columns);
    let sortKey = '';
    let sortDes = 'False';
    if (tableColKeys.length > 0) {
      // sorting exists
      for (let key in tableSettings.columns) {
        let sortValue = tableSettings.columns[key].sort;
        if (sortValue === 'asc' || sortValue === 'desc') {
          sortKey = key;
          sortDes = sortValue === 'desc' ? 'True' : 'False';
          break;
        }
      }
      this.assetTable.View.SortColumn = sortKey;
      this.assetTable.View.SortDesc = sortDes;

      this.listOptions.SortColumn = sortKey;
      this.listOptions.SortDesc = sortDes;
    }
    this.assetTable.View.Page = tableSettings.page;
    this.listOptions.Page = tableSettings.page;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);

    // Object comparision as tableSettings sends multiple events.
    if (this.utilityService.deepEqual(oldOptions, this.listOptions)) return;
    this.assetTable.SearchUpdated();
  }

  updateSearchTerm(searchText: string) {
    if (this.initialLoad) return;
    this.assetTable.View.Search = searchText;
    this.listOptions.Search = searchText;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);
    this.assetTable.SearchUpdated();
  }

  rowClicked(rowData: any) {
    this.utilityService.openAsset(rowData.Id);
  }

  toggleBookmark(isBookmarked: boolean) {
    if (!isBookmarked) {
      this.url = window.location.href;
      this.bookmarkName = this.site?.Name;
      const dialogRef = this.dialog.open(HrtBookmarkComponent, {
        data: { url: this.url, bookmarkName: this.bookmarkName },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.bookmarkName = result;
        if (result) {
          this.api.Bookmark.AddBookmark(
            this.id,
            this.bookmarkType,
            this.url,
            this.bookmarkName
          ).subscribe({
            next: (x) => {
              this.isBookmark = !this.isBookmark;
            },
          });
          let existingBookmarks = JSON.parse(
            sessionStorage.getItem('UserBookmarks') || ''
          );
          existingBookmarks.push({
            ForeignKey: this.id,
            BookmarkType: this.bookmarkType,
          });
          sessionStorage.setItem(
            'UserBookmarks',
            JSON.stringify(existingBookmarks)
          );
        }
      });
    } else {
      this.api.Bookmark.DeleteBookmark(this.id, this.bookmarkType).subscribe({
        next: (x) => {
          this.isBookmark = !this.isBookmark;
        },
      });
      let existingBookmarks = JSON.parse(
        sessionStorage.getItem('UserBookmarks') || ''
      );
      existingBookmarks = existingBookmarks.filter(
        (bookmark: { ForeignKey: number; BookmarkType: string }) =>
          !(
            bookmark.ForeignKey === this.id &&
            bookmark.BookmarkType === this.bookmarkType
          )
      );
      sessionStorage.setItem(
        'UserBookmarks',
        JSON.stringify(existingBookmarks)
      );
    }
  }

  ngOnDestroy() {
    clearInterval(this.socketInterval);
    sessionStorage.setItem(
      this.tableId + '-listOptions',
      JSON.stringify(this.listOptions)
    );
  }
}
