import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  activeTabKeys = {
    
  };
  constructor(
    private router: Router
  ) { }

  getFromSession(key: string) {
    return sessionStorage.getItem(key);
  }

  setToSession(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getActiveTabIndex(tabName: string) {
    // dashboardActiveIndex
    return parseInt(sessionStorage.getItem(tabName) || '0');
  }

  setActiveTab(index: number, tabName: string) {
    sessionStorage.setItem(tabName, index.toString());
  }

  openAsset(id: number) {
    this.router.navigate(['/asset-details', { id: id }]);
  }

  openSite(id: number) {
    this.router.navigate(['/site-details', { id: id }]);
  }

  deepEqual(obj1: any, obj2: any) {
    // Base case: if both objects are strictly equal, return true
    if (obj1 === obj2) {
      return true;
    }

    // Check if both objects are objects
    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 === null ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if both objects have the same number of keys
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if all keys in obj1 exist in obj2 and have deep equality
    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    // If all checks pass, the objects are considered equal
    return true;
  }

  formatDate(dateStr: string): string {
    if (!dateStr) return '';
    const utcDateString = dateStr.endsWith('Z') ? dateStr : dateStr + 'Z';
    const date = new Date(utcDateString);
    if (date.getFullYear() < 1980 || !date.valueOf()) return 'N/A';
    return date.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  }

  isDateRangeValid(startDate: string, endDate: string) {
    // check if it a valid date
      const startDateVal = new Date(startDate);
      const endDateVal = new Date(endDate);
      // check if start date is less than end date
      return !isNaN(startDateVal.valueOf()) && !isNaN(endDateVal.valueOf()) && startDateVal < endDateVal;
  }
}
