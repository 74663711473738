import { Injectable } from '@angular/core';
import { AssetMapData } from './api/assets';
import { RegionDataService } from './region-data.service';
import { ServiceTypes } from '../data/service-types';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  dashboardActiveTabIndex: number = 0;

  constructor(
    private regionData: RegionDataService
  ) { }


  normalizeCountryData(rawCountryData: AssetMapData[]): (string | number)[][] {
    let countries: (string | number)[][] = [];
    for (const country of rawCountryData) {
      let lookup3Digit = this.regionData.Countries.find(
        (x) => x.Code3 == country.Area
      );
      let lookupName = this.regionData.Countries.find(
        (x) => x.Name == country.Area
      );
      let finalCode = lookup3Digit?.Code2 ?? lookupName?.Code2 ?? country.Area;

      let countryOutput = countries.find((x) => x[0] == finalCode);
      if (countryOutput === undefined) {
        countryOutput = [finalCode, 0];
        countries.push(countryOutput);
      }
      countryOutput[1] = <number>countryOutput[1] + country.Count;
    }
    return countries;
  }

  formatDate(dateStr: string) {
    return new Date(dateStr).toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  getTypeDescription(id: number) {
    return ServiceTypes.find((x) => x.Id == id)?.Name ?? '';
  }

}

export enum DashboardQueryType {
  Sites = 1,
  Assets = 2,
  ServiceData = 3,
  OperatingData = 4,
}

export enum DashboardServiceCondition {
  All = -1,
  Ok = 1,
  Warning = 2,
  Serious = 3,
}